/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Badge, Divider, Flex, Text } from "@aws-amplify/ui-react";
export default function Auditcardsmall(props) {
  const { audits, overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="row"
      height="65px"
      alignItems="flex-start"
      position="relative"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "Auditcardsmall")}
    >
      <Flex
        gap="0"
        direction="row"
        width="1048px"
        height="65px"
        alignItems="center"
        shrink="0"
        overflow="hidden"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "ptcard-small")}
      >
        <Flex
          gap="0"
          direction="column"
          height="65px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          overflow="hidden"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="5px 32px 5px 32px"
          {...getOverrideProps(overrides, "Card Area32532800")}
        >
          <Flex
            gap="0"
            direction="column"
            height="24px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 421")}
          >
            <Text
              fontFamily="Poppins"
              fontSize="16px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={audits?.agentemail}
              {...getOverrideProps(overrides, "Susan F. Queue")}
            ></Text>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="fit-content"
          height="65px"
          alignItems="center"
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Card Area32532807")}
        >
          <Divider
            width="2px"
            height="65px"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider32532808")}
          ></Divider>
          <Flex
            gap="0"
            direction="column"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="72px"
            position="relative"
            padding="11px 14px 11px 14px"
            {...getOverrideProps(overrides, "Card Area32532809")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              color="rgba(92,102,112,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              letterSpacing="0px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Type"
              {...getOverrideProps(overrides, "label goes here32532810")}
            ></Text>
            <Badge
              display="flex"
              gap="5px"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="99px"
              backgroundColor="rgba(239,240,240,1)"
              size="small"
              variation="default"
              children={audits?.audittype}
              {...getOverrideProps(overrides, "Badge32532811")}
            ></Badge>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="72px"
            position="relative"
            padding="11px 14px 11px 14px"
            {...getOverrideProps(overrides, "Card Area32532812")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              color="rgba(92,102,112,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              letterSpacing="0px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="CC Contact ID"
              {...getOverrideProps(overrides, "label goes here32532813")}
            ></Text>
            <Badge
              display="flex"
              gap="5px"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="99px"
              backgroundColor="rgba(239,240,240,1)"
              size="small"
              variation="default"
              children={audits?.contactID}
              {...getOverrideProps(overrides, "Badge32532814")}
            ></Badge>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="72px"
            position="relative"
            padding="11px 14px 11px 14px"
            {...getOverrideProps(overrides, "Card Area32532815")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              color="rgba(92,102,112,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              letterSpacing="0px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Overall Score"
              {...getOverrideProps(overrides, "label goes here32532816")}
            ></Text>
            <Badge
              display="flex"
              gap="5px"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="99px"
              backgroundColor="rgba(239,240,240,1)"
              size="small"
              variation="default"
              children={audits?.call_overallscore}
              {...getOverrideProps(overrides, "Badge32532817")}
            ></Badge>
          </Flex>
          <Divider
            width="2px"
            height="65px"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider32532824")}
          ></Divider>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          height="65px"
          alignItems="center"
          grow="1"
          basis="438px"
          alignSelf="stretch"
          position="relative"
          padding="20px 32px 20px 32px"
          {...getOverrideProps(overrides, "Frame 438")}
        >
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="400"
            color="rgba(64,170,191,1)"
            lineHeight="24px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="center"
            letterSpacing="0px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={audits?.auditornotes}
            {...getOverrideProps(overrides, "Enrollment Status")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
