/* src/App.js */
import { withAuthenticator, Flex, Button, Expander, ExpanderItem } from '@aws-amplify/ui-react';
import "amazon-connect-streams";
import { CCP } from './CCP.js';
import '@aws-amplify/ui-react/styles.css';
import "./App.css";
import React, { useEffect, useState } from 'react';
import Amplify, { API, graphqlOperation } from 'aws-amplify';
import { createPatient } from './graphql/mutations';
import { listPatients } from './graphql/queries';
import { CreatePatient, CreateAudit4, PtcardsmallCollection, AgentNavBar, EditPatient, CreateStudy, StudyCardCollection, DefaultNavBar, SiteNavBar, StudyDetails, StudyDetailsCollection, Ptcardsmall, AuditcardsmallCollection } from './ui-components';
import awsExports from "./aws-exports";
import {AmplifyProvider} from "@aws-amplify/ui-react";
import { Hub } from 'aws-amplify';
import { DataStore } from "@aws-amplify/datastore";
import {BrowserRouter, Routes, Route, } from "react-router-dom";
import Ctgov from './services/ctgov';
import Form from "./components/Form";


Amplify.configure(awsExports);
DataStore.configure(awsExports);


function App() {

  useEffect(() => {
    Hub.listen('ui', capsule => {
      if (capsule.payload.event.includes("actions:datastore")) {
        console.log(capsule)
      }
    })
  }, [])


const initialState = { name: '', description: '', primaryphone: '', secondaryphone: '', email: '', DOB: '', MRN: '' }


  const [formState, setFormState] = useState(initialState)
  const [Patients, setPatients] = useState([])
  const [updatePatient, setUpdatePatient] = useState()
  

  useEffect(() => {
    fetchPatients()
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  async function fetchPatients() {
    try {
      const PatientData = await API.graphql(graphqlOperation(listPatients))
      const Patients = PatientData.data.listPatients.items
      setPatients(Patients)
    } catch (err) { console.log('error fetching Patients') }
  }

  async function addPatient() {
    try {
      if (!formState.name || !formState.primaryphone || !formState.email ) return
      const Patient = { ...formState }
      setPatients([...Patients, Patient])
      setFormState(initialState)
      await API.graphql(graphqlOperation(createPatient, {input: Patient}))
    } catch (err) {
      console.log('error creating Patient:', err)
    }
  }

  return (
    
    <AmplifyProvider>
      
        <BrowserRouter>
        
        

          
          <div style={styles.container}>
          
          <Routes>
            <Route path="/" element={<Flex direction="column"
              justifyContent="top"
              alignItems="center"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
              padding="1rem"><DefaultNavBar /><StudyCardCollection /></Flex>} />
            <Route path="createpatient" element={<CreatePatient />} />
            <Route path="reports/agent" element={<><div><AgentNavBar /></div><Flex direction="column"
              justifyContent="top"
              background-color='#def9fa'
              alignItems="center"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
              padding="1rem"><iframe
        width="1200"
        height="1800"
        src="https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/060085285033/dashboards/95195cf1-941c-4a42-afd2-886a1f859cd1?directory_alias=javara-research">
    </iframe>
    <iframe
        width="1200"
        height="1000"
        src="https://us-east-1.quicksight.aws.amazon.com/sn/embed/share/accounts/060085285033/dashboards/feb7034f-3710-4da2-bf07-39925e3d306b?directory_alias=javara-research">
    </iframe></Flex></>} />
            <Route path="listctgovstudy" element={<><div><SiteNavBar /></div>{<Form />}</>} />
            <Route path="wisdom" element={<><div><AgentNavBar /></div>{<CreatePatient />}</>} />
            <Route path="audit" element={<><div><AgentNavBar /></div><Flex direction="column"
              justifyContent="top"
              background-color='#def9fa'
              alignItems="center"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
              padding="1rem"><CreateAudit4 /><AuditcardsmallCollection /></Flex></>} />
            <Route path="liststudy" element={<><div><SiteNavBar /></div>{<Flex><StudyCardCollection /></Flex>}</>} />
            <Route path="agentdashboard" element={<><div><AgentNavBar /></div>{"Team dashboard here"}</>} />
            <Route path=":userEmail" element={<><div><DefaultNavBar /></div>{"Your stats here"}</>} />
            <Route path="study/faq" element={<><div><DefaultNavBar /></div>{"FAQ's about studies"}</>} />
            <Route path="patient/signup" element={<><div><DefaultNavBar /></div><Flex direction="column"
              justifyContent="top"
              background-color='#def9fa'
              alignItems="center"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
              padding="1rem"><StudyDetailsCollection /></Flex></>} />
            <Route path="study" element={<><div><DefaultNavBar /></div>{<StudyCardCollection />}</>} />
            <Route path="patient" element={<><div><DefaultNavBar /></div>{<Ptcardsmall />}</>} />
            <Route path="study/:study"  element={<Flex direction="column"
              justifyContent="top"
              alignItems="center"
              alignContent="flex-start"
              wrap="nowrap"
              gap="1rem"
              padding="1rem"><><div><DefaultNavBar /></div> {<StudyDetails />}</></Flex>} />
            <Route path="patientqueue" element={
              <><div><AgentNavBar /></div><Flex padding="1rem"><PtcardsmallCollection overrideItems={({ item }) => ({
                    onClick: () => setUpdatePatient(item)
                  })} />
                    <Flex
                      direction="column"
                      justifyContent="top"
                      alignItems="center"
                      alignContent="flex-start"
                      wrap="nowrap"
                      gap="1rem"
                      padding="1rem"
                    >

                      <CCP />
                      <EditPatient patient={updatePatient} />
                      <Expander type="single" isCollapsible={true}>
                        <ExpanderItem title="Agent Stats here?" value="item-1">
                          Maybe calls for the day, prescreens for week, ACW times, etc
                        </ExpanderItem>
                        <ExpanderItem title="Create New Patient" value="item-2">
                          <CreatePatient />
                        </ExpanderItem>
                      </Expander>
                    </Flex>
                  </Flex></>
            } />
          </Routes>
          
          </div>       

          
        </BrowserRouter>
        
    </AmplifyProvider>
    
  );
}

const styles = {
  container: { align: 'center', width: '1440px', margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 'auto' },
  Patient: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  PatientName: { fontSize: 20, fontWeight: 'bold' },
  PatientDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

export default withAuthenticator(App);