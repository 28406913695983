// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { Audits, Study, Patient } = initSchema(schema);

export {
  Audits,
  Study,
  Patient
};