import React, { Component } from 'react';
import ccpurl from './connect-url.js'; 
import "amazon-connect-streams";

class CCP extends Component {
  constructor(props) {
    super(props);
    this.containerDiv = React.createRef();
  }

  componentDidMount() {
    // eslint-disable-next-line no-undef
    connect.core.initCCP(this.containerDiv.current, {
      ccpUrl: ccpurl,
      loginPopup: true,
      loginPopupAutoClose: true,
      loginOptions: {                 // optional, if provided opens login in new window
        autoClose: true,              // optional, defaults to `false`
        height: 600,                  // optional, defaults to 578
        width: 400,                   // optional, defaults to 433
        top: 0,                       // optional, defaults to 0
        left: 0                       // optional, defaults to 0
      },
      region: "us-east-1",         // REQUIRED for `CHAT`, optional otherwise
          softphone: {                    // optional, defaults below apply if not provided
            allowFramedSoftphone: true,   // optional, defaults to false
            disableRingtone: false,       // optional, defaults to false
            ringtoneUrl: "./ringtone.mp3" // optional, defaults to CCP’s default ringtone if a falsy value is set
          },
          pageOptions: { //optional
            enableAudioDeviceSettings: false, //optional, defaults to 'false'
            enablePhoneTypeSettings: true //optional, defaults to 'true' 
          },
          ccpAckTimeout: 5000, //optional, defaults to 3000 (ms)
          ccpSynTimeout: 3000, //optional, defaults to 1000 (ms)
          ccpLoadTimeout: 10000 //optional, defaults to 5000 (ms)
          });
  }

  render() {
    return (
      <div 
        className="containerDiv"
        ref={this.containerDiv}
        style={{height: 465, width: 300}}
      />
    );
  }
}

export {CCP}