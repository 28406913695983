/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  useDataStoreCreateAction,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import { Patient } from "../models";
import { schema } from "../models/schema";
import { Button, Flex, Text, TextField } from "@aws-amplify/ui-react";
export default function CreatePatient(props) {
  const { CreatePatient, overrides, ...rest } = props;
  const [nameinputValue, setNameinputValue] = useStateMutationAction("");
  const [primaryphoneinputValue, setPrimaryphoneinputValue] =
    useStateMutationAction("");
  const [secondaryphoneinputValue, setSecondaryphoneinputValue] =
    useStateMutationAction("");
  const [dateofbirthinputValue, setDateofbirthinputValue] =
    useStateMutationAction("");
  const [emailinputValue, setEmailinputValue] = useStateMutationAction("");
  const [medicalrecordnumberinputValue, setMedicalrecordnumberinputValue] =
    useStateMutationAction("");
  const [ccidinputValue, setCcidinputValue] = useStateMutationAction("");
  const buttonOnClick = useDataStoreCreateAction({
    fields: {
      name: nameinputValue,
      primaryphone: primaryphoneinputValue,
      secondaryphone: secondaryphoneinputValue,
      dateofbirth: dateofbirthinputValue,
      email: emailinputValue,
      medicalrecordno: medicalrecordnumberinputValue,
      clinconid: ccidinputValue,
    },
    model: Patient,
    schema: schema,
  });
  return (
    <Flex
      gap="10px"
      direction="row"
      alignItems="flex-start"
      position="relative"
      padding="0px 0px 0px 0px"
      as="form"
      {...rest}
      {...getOverrideProps(overrides, "CreatePatient")}
    >
      <Flex
        gap="0"
        direction="row"
        width="330px"
        height="693px"
        alignItems="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "CreatePatient308219980")}
      >
        <Flex
          gap="0"
          direction="row"
          height="693px"
          alignItems="flex-start"
          grow="1"
          basis="330px"
          alignSelf="stretch"
          overflow="hidden"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large")}
        >
          <Flex
            gap="16px"
            direction="column"
            height="693px"
            justifyContent="center"
            alignItems="center"
            grow="1"
            basis="330px"
            alignSelf="stretch"
            position="relative"
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area")}
          >
            <Text
              fontFamily="Inter"
              fontSize="large"
              fontWeight="bold"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Create Patient"
              {...getOverrideProps(overrides, "Create Patient")}
            ></Text>
            <TextField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              height="64px"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              isMultiline={false}
              label="Full Name"
              value={nameinputValue}
              onChange={(event) => {
                setNameinputValue(event.target.value);
              }}
              {...getOverrideProps(overrides, "Nameinput")}
            ></TextField>
            <TextField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              height="64px"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              isMultiline={false}
              label="Primary Phone"
              value={primaryphoneinputValue}
              onChange={(event) => {
                setPrimaryphoneinputValue(event.target.value);
              }}
              {...getOverrideProps(overrides, "primaryphoneinput")}
            ></TextField>
            <TextField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              height="64px"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              isMultiline={false}
              label="Secondary Phone"
              value={secondaryphoneinputValue}
              onChange={(event) => {
                setSecondaryphoneinputValue(event.target.value);
              }}
              {...getOverrideProps(overrides, "secondaryphoneinput")}
            ></TextField>
            <TextField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              height="64px"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              isMultiline={false}
              label="Date of Birth"
              value={dateofbirthinputValue}
              onChange={(event) => {
                setDateofbirthinputValue(event.target.value);
              }}
              {...getOverrideProps(overrides, "dateofbirthinput")}
            ></TextField>
            <TextField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              height="64px"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              isMultiline={false}
              label="Email Address"
              value={emailinputValue}
              onChange={(event) => {
                setEmailinputValue(event.target.value);
              }}
              {...getOverrideProps(overrides, "emailinput")}
            ></TextField>
            <TextField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              height="64px"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              isMultiline={false}
              label="Medical Record Number (MRN)"
              value={medicalrecordnumberinputValue}
              onChange={(event) => {
                setMedicalrecordnumberinputValue(event.target.value);
              }}
              {...getOverrideProps(overrides, "medicalrecordnumberinput")}
            ></TextField>
            <TextField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              height="64px"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={false}
              variation="default"
              isMultiline={false}
              label="Clinical Conductor ID (CCID)"
              value={ccidinputValue}
              onChange={(event) => {
                setCcidinputValue(event.target.value);
              }}
              {...getOverrideProps(overrides, "ccidinput")}
            ></TextField>
            <Button
              display="flex"
              direction="row"
              width="contain"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              position="relative"
              borderRadius="4px"
              size="small"
              isDisabled={false}
              variation="primary"
              children="Create New Patient"
              onClick={() => {
                buttonOnClick();
              }}
              {...getOverrideProps(overrides, "Button")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
