/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  useDataStoreCreateAction,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import { Audits } from "../models";
import { schema } from "../models/schema";
import {
  Button,
  Divider,
  Flex,
  StepperField,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
export default function CreateAudit4(props) {
  const { as = "form", overrides, ...rest } = props;
  const [dateofbirthinputValue, setDateofbirthinputValue] =
    useStateMutationAction("");
  const [
    nameinputThreeTwoSixThreeSixEightThreeFiveValue,
    setNameinputThreeTwoSixThreeSixEightThreeFiveValue,
  ] = useStateMutationAction("");
  const [emailinputValue, setEmailinputValue] = useStateMutationAction("");
  const [
    nameinputThreeTwoSixThreeSixEightThreeSevenValue,
    setNameinputThreeTwoSixThreeSixEightThreeSevenValue,
  ] = useStateMutationAction("");
  const [primaryphoneinputValue, setPrimaryphoneinputValue] =
    useStateMutationAction("");
  const [secondaryphoneinputValue, setSecondaryphoneinputValue] =
    useStateMutationAction("");
  const [
    stepperFieldThreeTwoSixThreeSixEightFourSixValue,
    setStepperFieldThreeTwoSixThreeSixEightFourSixValue,
  ] = useStateMutationAction("3");
  const [
    stepperFieldThreeTwoSixThreeSixEightFiveOneValue,
    setStepperFieldThreeTwoSixThreeSixEightFiveOneValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixEightFiveSixValue,
    setStepperFieldThreeTwoSixThreeSixEightFiveSixValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixEightSixOneValue,
    setStepperFieldThreeTwoSixThreeSixEightSixOneValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixEightSixSixValue,
    setStepperFieldThreeTwoSixThreeSixEightSixSixValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixEightSevenOneValue,
    setStepperFieldThreeTwoSixThreeSixEightSevenOneValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixEightNineThreeValue,
    setStepperFieldThreeTwoSixThreeSixEightNineThreeValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixEightSevenEightValue,
    setStepperFieldThreeTwoSixThreeSixEightSevenEightValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixEightEightThreeValue,
    setStepperFieldThreeTwoSixThreeSixEightEightThreeValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixEightEightEightValue,
    setStepperFieldThreeTwoSixThreeSixEightEightEightValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixEightNineEightValue,
    setStepperFieldThreeTwoSixThreeSixEightNineEightValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixNineZeroThreeValue,
    setStepperFieldThreeTwoSixThreeSixNineZeroThreeValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixNineOneZeroValue,
    setStepperFieldThreeTwoSixThreeSixNineOneZeroValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixNineOneFiveValue,
    setStepperFieldThreeTwoSixThreeSixNineOneFiveValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixNineTwoZeroValue,
    setStepperFieldThreeTwoSixThreeSixNineTwoZeroValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixNineTwoFiveValue,
    setStepperFieldThreeTwoSixThreeSixNineTwoFiveValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixNineThreeZeroValue,
    setStepperFieldThreeTwoSixThreeSixNineThreeZeroValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixNineThreeFiveValue,
    setStepperFieldThreeTwoSixThreeSixNineThreeFiveValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixNineFourZeroValue,
    setStepperFieldThreeTwoSixThreeSixNineFourZeroValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixNineFourFiveValue,
    setStepperFieldThreeTwoSixThreeSixNineFourFiveValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixNineFiveZeroValue,
    setStepperFieldThreeTwoSixThreeSixNineFiveZeroValue,
  ] = useStateMutationAction(undefined);
  const [
    stepperFieldThreeTwoSixThreeSixNineFiveSevenValue,
    setStepperFieldThreeTwoSixThreeSixNineFiveSevenValue,
  ] = useStateMutationAction(undefined);
  const [
    nameinputThreeTwoSixSixSevenFiveOneFiveValue,
    setNameinputThreeTwoSixSixSevenFiveOneFiveValue,
  ] = useStateMutationAction("");
  const buttonOnClick = useDataStoreCreateAction({
    fields: {
      agentemail: dateofbirthinputValue,
      audittype: nameinputThreeTwoSixThreeSixEightThreeFiveValue,
      inboundoroutbound: emailinputValue,
      patientphone: nameinputThreeTwoSixThreeSixEightThreeSevenValue,
      contactID: primaryphoneinputValue,
      clinconID: secondaryphoneinputValue,
      call_greeting: stepperFieldThreeTwoSixThreeSixEightFourSixValue,
      call_IDverify: stepperFieldThreeTwoSixThreeSixEightFiveOneValue,
      call_introscript: stepperFieldThreeTwoSixThreeSixEightFiveSixValue,
      call_rebuttals: stepperFieldThreeTwoSixThreeSixEightSixOneValue,
      call_narrative: stepperFieldThreeTwoSixThreeSixEightSixSixValue,
      call_consent: stepperFieldThreeTwoSixThreeSixEightSevenOneValue,
      cc_demographics: stepperFieldThreeTwoSixThreeSixEightNineThreeValue,
      call_criteria: stepperFieldThreeTwoSixThreeSixEightSevenEightValue,
      call_outcome: stepperFieldThreeTwoSixThreeSixEightEightThreeValue,
      call_schedflex: stepperFieldThreeTwoSixThreeSixEightEightEightValue,
      cc_dboptin: stepperFieldThreeTwoSixThreeSixEightNineEightValue,
      cc_agentnotes: stepperFieldThreeTwoSixThreeSixNineZeroThreeValue,
      call_confirmationemail: stepperFieldThreeTwoSixThreeSixNineOneZeroValue,
      cc_patienttouch: stepperFieldThreeTwoSixThreeSixNineOneFiveValue,
      call_closing: stepperFieldThreeTwoSixThreeSixNineTwoZeroValue,
      call_ACWtime: stepperFieldThreeTwoSixThreeSixNineTwoFiveValue,
      call_agentsentiment: stepperFieldThreeTwoSixThreeSixNineThreeZeroValue,
      call_length: stepperFieldThreeTwoSixThreeSixNineThreeFiveValue,
      call_pausesholds: stepperFieldThreeTwoSixThreeSixNineFourZeroValue,
      call_explanation: stepperFieldThreeTwoSixThreeSixNineFourFiveValue,
      cc_schedule: stepperFieldThreeTwoSixThreeSixNineFiveZeroValue,
      call_overallscore: stepperFieldThreeTwoSixThreeSixNineFiveSevenValue,
      auditornotes: nameinputThreeTwoSixSixSevenFiveOneFiveValue,
    },
    model: Audits,
    schema: schema,
  });
  return (
    <Flex
      gap="0"
      direction="column"
      width="769px"
      alignItems="center"
      position="relative"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      {...rest}
      {...getOverrideProps(overrides, "CreateAudit4")}
    >
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "CreatePatient32636831")}
      >
        <Flex
          gap="14px"
          direction="column"
          width="769px"
          justifyContent="center"
          alignItems="center"
          grow="1"
          basis="769px"
          height="29px"
          overflow="hidden"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="0px 0px 0px 0px"
          backgroundColor="rgba(97,218,251,1)"
          {...getOverrideProps(overrides, "ptcard-large32636832")}
        >
          <Text
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="700"
            color="rgba(255,255,255,1)"
            lineHeight="28.125px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="QA Audit"
            {...getOverrideProps(overrides, "QA Audit")}
          ></Text>
        </Flex>
      </Flex>
      <TextField
        display="flex"
        gap="4px"
        direction="column"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        isMultiline={false}
        placeholder="Agent Email"
        value={dateofbirthinputValue}
        onChange={(event) => {
          setDateofbirthinputValue(event.target.value);
        }}
        {...getOverrideProps(overrides, "dateofbirthinput")}
      ></TextField>
      <TextField
        display="flex"
        gap="4px"
        direction="column"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        isMultiline={false}
        placeholder="Audit Type"
        value={nameinputThreeTwoSixThreeSixEightThreeFiveValue}
        onChange={(event) => {
          setNameinputThreeTwoSixThreeSixEightThreeFiveValue(
            event.target.value
          );
        }}
        {...getOverrideProps(overrides, "Nameinput32636835")}
      ></TextField>
      <TextField
        display="flex"
        gap="4px"
        direction="column"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        isMultiline={false}
        placeholder="Inbound or Outbound"
        value={emailinputValue}
        onChange={(event) => {
          setEmailinputValue(event.target.value);
        }}
        {...getOverrideProps(overrides, "emailinput")}
      ></TextField>
      <TextField
        display="flex"
        gap="4px"
        direction="column"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        isMultiline={false}
        placeholder="Patient Phone"
        value={nameinputThreeTwoSixThreeSixEightThreeSevenValue}
        onChange={(event) => {
          setNameinputThreeTwoSixThreeSixEightThreeSevenValue(
            event.target.value
          );
        }}
        {...getOverrideProps(overrides, "Nameinput32636837")}
      ></TextField>
      <TextField
        display="flex"
        gap="4px"
        direction="column"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        isMultiline={false}
        placeholder="Connect Contact ID"
        value={primaryphoneinputValue}
        onChange={(event) => {
          setPrimaryphoneinputValue(event.target.value);
        }}
        {...getOverrideProps(overrides, "primaryphoneinput")}
      ></TextField>
      <TextField
        display="flex"
        gap="4px"
        direction="column"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="10px 0px 10px 0px"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        isMultiline={false}
        placeholder="Clinical Conductor ID"
        value={secondaryphoneinputValue}
        onChange={(event) => {
          setSecondaryphoneinputValue(event.target.value);
        }}
        {...getOverrideProps(overrides, "secondaryphoneinput")}
      ></TextField>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="700"
        color="rgba(0,0,0,1)"
        lineHeight="18.75px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Introduction"
        {...getOverrideProps(overrides, "Criteria32636840")}
      ></Text>
      <Divider
        width="450px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(0,0,0,1)"
        size="large"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider32636841")}
      ></Divider>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636842")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636843")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636844")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Was the greeting professional and friendly? "
              {...getOverrideProps(
                overrides,
                "Was the greeting professional and friendly?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              min="1"
              max="5"
              value={stepperFieldThreeTwoSixThreeSixEightFourSixValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixEightFourSixValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636846")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636847")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636848")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636849")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Was the patient’s information verified using 3 out of the 4 patient identifiers?"
              {...getOverrideProps(
                overrides,
                "Was the patient\u2019s information verified using 3 out of the 4 patient identifiers?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixEightFiveOneValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixEightFiveOneValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636851")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636852")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636853")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636854")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Did the agent adhere to the introduction script?"
              {...getOverrideProps(
                overrides,
                "Did the agent adhere to the introduction script?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixEightFiveSixValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixEightFiveSixValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636856")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636857")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636858")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636859")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Did the agent satisfactorily answer any questions regarding why we are calling if the patient was combative? (Refer to rebuttals in wisdom)"
              {...getOverrideProps(
                overrides,
                "Did the agent satisfactorily answer any questions regarding why we are calling if the patient was combative? (Refer to rebuttals in wisdom)"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixEightSixOneValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixEightSixOneValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636861")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636862")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636863")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636864")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Did the agent adequately explain the study details based on the provided script and answer any questions the patient might have? And read the narrative?"
              {...getOverrideProps(
                overrides,
                "Did the agent adequately explain the study details based on the provided script and answer any questions the patient might have? And read the narrative?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixEightSixSixValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixEightSixSixValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636866")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636867")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636868")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636869")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Did the agent consent the patient?"
              {...getOverrideProps(
                overrides,
                "Did the agent consent the patient?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixEightSevenOneValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixEightSevenOneValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636871")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="700"
        color="rgba(0,0,0,1)"
        lineHeight="18.75px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        width="80px"
        height="19px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Screening"
        {...getOverrideProps(overrides, "Criteria32636872")}
      ></Text>
      <Divider
        width="450px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(0,0,0,1)"
        size="large"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider32636873")}
      ></Divider>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636874")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636875")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636876")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Did they read the criteria questions accurately and answer any questions from the patient?"
              {...getOverrideProps(
                overrides,
                "Did they read the criteria questions accurately and answer any questions from the patient?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixEightSevenEightValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixEightSevenEightValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636878")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636879")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636880")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636881")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Did they let the patient know that they qualified or did not qualify for the study?"
              {...getOverrideProps(
                overrides,
                "Did they let the patient know that they qualified or did not qualify for the study?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixEightEightThreeValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixEightEightThreeValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636883")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636884")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636885")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636886")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Did they ask the patient’s availability and try to be flexible around their needs?"
              {...getOverrideProps(
                overrides,
                "Did they ask the patient\u2019s availability and try to be flexible around their needs?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixEightEightEightValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixEightEightEightValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636888")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636889")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636890")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636891")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Did the agent verify the demographic information and copy paste or enter any missing information from AWS?  "
              {...getOverrideProps(
                overrides,
                "Did the agent verify the demographic information and copy paste or enter any missing information from AWS?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixEightNineThreeValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixEightNineThreeValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636893")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636894")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636895")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636896")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Did they accurately ask and collect opt-in for Clinical Conductor?"
              {...getOverrideProps(
                overrides,
                "Did they accurately ask and collect opt-in for Clinical Conductor?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixEightNineEightValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixEightNineEightValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636898")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636899")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636900")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636901")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Did they record notes applicable to screening and/or scheduling?"
              {...getOverrideProps(
                overrides,
                "Did they record notes applicable to screening and/or scheduling?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixNineZeroThreeValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixNineZeroThreeValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636903")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="700"
        color="rgba(0,0,0,1)"
        lineHeight="18.75px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        width="70px"
        height="19px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Wrap-up"
        {...getOverrideProps(overrides, "Criteria32636904")}
      ></Text>
      <Divider
        width="450px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(0,0,0,1)"
        size="large"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider32636905")}
      ></Divider>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636906")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636907")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636908")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Confirmation Email - Was it sent and was it accurate?"
              {...getOverrideProps(
                overrides,
                "Confirmation Email - Was it sent and was it accurate?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixNineOneZeroValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixNineOneZeroValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636910")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636911")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636912")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636913")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children='CC Patient touches - Did they choose the correct option in "What prompted this call"?'
              {...getOverrideProps(
                overrides,
                'CC Patient touches - Did they choose the correct option in "What prompted this call"?'
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixNineOneFiveValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixNineOneFiveValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636915")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636916")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636917")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636918")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Call Closing - How well did the agent wrap up the call?&#xD;"
              {...getOverrideProps(
                overrides,
                "Call Closing - How well did the agent wrap up the call?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixNineTwoZeroValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixNineTwoZeroValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636920")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636921")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636922")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636923")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="ACW Time - Did the agent spend only a reasonable amount of time doing after-call work?"
              {...getOverrideProps(
                overrides,
                "ACW Time - Did the agent spend only a reasonable amount of time doing after-call work?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixNineTwoFiveValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixNineTwoFiveValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636925")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636926")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636927")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636928")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Agent Sentiment - Rate the overall agent sentiment&#xD;"
              {...getOverrideProps(
                overrides,
                "Agent Sentiment - Rate the overall agent sentiment"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixNineThreeZeroValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixNineThreeZeroValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636930")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636931")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636932")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636933")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Call Length - Was the call a reasonable length, or could better call control been used?"
              {...getOverrideProps(
                overrides,
                "Call Length - Was the call a reasonable length, or could better call control been used?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixNineThreeFiveValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixNineThreeFiveValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636935")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636936")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636937")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636938")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Call Pauses and Holds - were there unnecessary pauses or holds during the call?"
              {...getOverrideProps(
                overrides,
                "Call Pauses and Holds - were there unnecessary pauses or holds during the call?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixNineFourZeroValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixNineFourZeroValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636940")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636941")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636942")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636943")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Dispo Explanation - Did the agent explain why the patient didnt qualify?"
              {...getOverrideProps(
                overrides,
                "Dispo Explanation - Did the agent explain why the patient didnt qualify?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixNineFourFiveValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixNineFourFiveValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636945")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636946")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636947")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636948")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="CC Scheduling Accuracy - Did the agent schedule the patient accurately (and in the right place) in Clinical Conductor?"
              {...getOverrideProps(
                overrides,
                "CC Scheduling Accuracy - Did the agent schedule the patient accurately (and in the right place) in Clinical Conductor?"
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixNineFiveZeroValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixNineFiveZeroValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636950")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <Text
        fontFamily="Inter"
        fontSize="16px"
        fontWeight="700"
        color="rgba(0,0,0,1)"
        lineHeight="18.75px"
        textAlign="left"
        display="flex"
        direction="column"
        justifyContent="flex-start"
        width="41px"
        height="19px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        whiteSpace="pre-wrap"
        children="Extra"
        {...getOverrideProps(overrides, "Criteria32636951")}
      ></Text>
      <Divider
        width="450px"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(0,0,0,1)"
        size="large"
        orientation="horizontal"
        {...getOverrideProps(overrides, "Divider32636952")}
      ></Divider>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="3px 0px 3px 0px"
        backgroundColor="rgba(255,255,255,1)"
        {...getOverrideProps(overrides, "QuestionArea32636953")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="70px"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636954")}
        >
          <Flex
            gap="68px"
            direction="row"
            width="769px"
            height="70px"
            justifyContent="space-between"
            alignItems="center"
            grow="1"
            basis="769px"
            position="relative"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636955")}
          >
            <Text
              fontFamily="Inter"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="18.75px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="415px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Overall Score - use this to adjust for anything not covered in other questions."
              {...getOverrideProps(
                overrides,
                "Overall Score - use this to adjust for anything not covered in other questions."
              )}
            ></Text>
            <StepperField
              display="flex"
              gap="4px"
              direction="column"
              width="300px"
              shrink="0"
              height="32px"
              position="relative"
              padding="0px 0px 0px 0px"
              size="default"
              isDisabled={false}
              labelHidden={true}
              variation="default"
              value={stepperFieldThreeTwoSixThreeSixNineFiveSevenValue}
              onStepChange={(value) =>
                setStepperFieldThreeTwoSixThreeSixNineFiveSevenValue(value)
              }
              {...getOverrideProps(overrides, "StepperField32636957")}
            ></StepperField>
          </Flex>
        </Flex>
      </Flex>
      <TextField
        display="flex"
        gap="4px"
        direction="column"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        size="default"
        isDisabled={false}
        labelHidden={false}
        variation="default"
        isMultiline={false}
        label="Auditor Notes"
        value={nameinputThreeTwoSixSixSevenFiveOneFiveValue}
        onChange={(event) => {
          setNameinputThreeTwoSixSixSevenFiveOneFiveValue(event.target.value);
        }}
        {...getOverrideProps(overrides, "Nameinput32667515")}
      ></TextField>
      <Flex
        gap="0"
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
        shrink="0"
        alignSelf="stretch"
        objectFit="cover"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor="rgba(0,0,0,1)"
        {...getOverrideProps(overrides, "CreatePatient32636959")}
      >
        <Flex
          gap="0"
          direction="row"
          width="769px"
          alignItems="flex-start"
          grow="1"
          basis="769px"
          height="46px"
          overflow="hidden"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "ptcard-large32636960")}
        >
          <Flex
            gap="68px"
            direction="row"
            height="46px"
            justifyContent="center"
            alignItems="center"
            grow="1"
            basis="769px"
            alignSelf="stretch"
            position="relative"
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            padding="6px 14px 6px 14px"
            backgroundColor="rgba(255,255,255,1)"
            {...getOverrideProps(overrides, "Card Area32636961")}
          >
            <Button
              display="flex"
              direction="row"
              width="141px"
              justifyContent="space-between"
              alignItems="center"
              shrink="0"
              height="34px"
              position="relative"
              borderRadius="4px"
              backgroundColor="rgba(26,188,254,1)"
              size="small"
              isDisabled={false}
              variation="primary"
              children="Submit Audit"
              onClick={() => {
                buttonOnClick();
              }}
              {...getOverrideProps(overrides, "Button")}
            ></Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
