/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import { Badge, Button, Divider, Flex, Text } from "@aws-amplify/ui-react";
export default function Ptcardsmall(props) {
  const { patient, overrides, ...rest } = props;
  const [ptcardsmallBackgroundColor, setPtcardsmallBackgroundColor] =
    useStateMutationAction(undefined);
  const ptcardsmallOnMouseLeave = () => {
    setPtcardsmallBackgroundColor("#ffffff");
  };
  const ptcardsmallOnMouseOut = () => {
    setPtcardsmallBackgroundColor("#edfcff");
  };
  return (
    <Flex
      gap="0"
      direction="row"
      height="65px"
      alignItems="flex-start"
      position="relative"
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
      padding="0px 0px 0px 0px"
      backgroundColor="rgba(255,255,255,1)"
      as="form"
      {...rest}
      {...getOverrideProps(overrides, "Ptcardsmall")}
    >
      <Flex
        gap="0"
        direction="row"
        width="1048px"
        height="65px"
        alignItems="center"
        shrink="0"
        overflow="hidden"
        position="relative"
        padding="0px 0px 0px 0px"
        backgroundColor={ptcardsmallBackgroundColor}
        onMouseLeave={() => {
          ptcardsmallOnMouseLeave();
        }}
        onMouseOut={() => {
          ptcardsmallOnMouseOut();
        }}
        {...getOverrideProps(overrides, "ptcard-small")}
      >
        <Flex
          gap="0"
          direction="column"
          height="65px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          alignSelf="stretch"
          overflow="hidden"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="5px 32px 5px 32px"
          width="250px"
          {...getOverrideProps(overrides, "Card Area288015584")}
        >
          <Flex
            gap="0"
            direction="column"
            height="24px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            overflow="hidden"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 421")}
          >
            <Text
              fontFamily="Poppins"
              fontSize="16px"
              fontWeight="700"
              color="rgba(13,26,38,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={patient?.name}
              {...getOverrideProps(overrides, "Susan F. Queue")}
            ></Text>
          </Flex>
          <Text
            fontFamily="Inter"
            fontSize="10px"
            fontWeight="400"
            color="rgba(92,102,112,1)"
            lineHeight="24px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="center"
            letterSpacing="0px"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={`${"MRN: "}${patient?.medicalrecordno}`}
            {...getOverrideProps(overrides, "label goes here30902464")}
          ></Text>
          <Flex
            gap="0"
            direction="row"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Frame 432")}
          >
            <Text
              fontFamily="Inter"
              fontSize="10px"
              fontWeight="400"
              color="rgba(92,102,112,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              letterSpacing="0px"
              width="118px"
              height="13px"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children={`${"CCID: "}${patient?.clinconid}`}
              {...getOverrideProps(overrides, "label goes here30902468")}
            ></Text>
            <Button
              display="flex"
              direction="row"
              width="fit-content"
              height="13px"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              size="small"
              isDisabled={false}
              variation="link"
              children="PUSH"
              {...getOverrideProps(overrides, "Button")}
            ></Button>
          </Flex>
        </Flex>
        <Flex
          gap="0"
          direction="row"
          width="700px"
          height="65px"
          alignItems="center"
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          wrap={true}
          {...getOverrideProps(overrides, "Card Area288015598")}
        >
          <Divider
            width="2px"
            height="65px"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider31157454")}
          ></Divider>
          <Flex
            gap="0"
            direction="column"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="72px"
            position="relative"
            padding="11px 14px 11px 14px"
            {...getOverrideProps(overrides, "Card Area288015754")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              color="rgba(92,102,112,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              letterSpacing="0px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Location / Referrer"
              {...getOverrideProps(overrides, "label goes here30892420")}
            ></Text>
            <Badge
              display="flex"
              gap="5px"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="99px"
              backgroundColor="rgba(239,240,240,1)"
              size="small"
              variation="default"
              fontSize="8px"
              children={`${patient?.site}${" | "}${patient?.primaryprovider}`}
              {...getOverrideProps(overrides, "Badge288015587")}
            ></Badge>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="72px"
            position="relative"
            padding="11px 14px 11px 14px"
            {...getOverrideProps(overrides, "Card Area30891921")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              color="rgba(92,102,112,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              letterSpacing="0px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Study"
              {...getOverrideProps(overrides, "label goes here30892415")}
            ></Text>
            <Badge
              display="flex"
              gap="5px"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="99px"
              backgroundColor="rgba(239,240,240,1)"
              size="small"
              variation="default"
              children={patient?.studyname}
              {...getOverrideProps(overrides, "Badge30891924")}
            ></Badge>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="72px"
            position="relative"
            padding="11px 14px 11px 14px"
            {...getOverrideProps(overrides, "Card Area30891965")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              color="rgba(92,102,112,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              letterSpacing="0px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Date of Birth"
              {...getOverrideProps(overrides, "label goes here30892410")}
            ></Text>
            <Badge
              display="flex"
              gap="5px"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="99px"
              backgroundColor="rgba(239,240,240,1)"
              size="small"
              variation="default"
              children={patient?.dateofbirth}
              {...getOverrideProps(overrides, "Badge30891969")}
            ></Badge>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="72px"
            position="relative"
            padding="11px 14px 11px 14px"
            {...getOverrideProps(overrides, "Card Area30892022")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              color="rgba(92,102,112,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              letterSpacing="0px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Email"
              {...getOverrideProps(overrides, "label goes here30892406")}
            ></Text>
            <Badge
              display="flex"
              gap="5px"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="99px"
              backgroundColor="rgba(125,161,232,1)"
              size="small"
              variation="default"
              fontSize="8px"
              children={patient?.email}
              {...getOverrideProps(overrides, "Badge30892029")}
            ></Badge>
          </Flex>
          <Flex
            gap="0"
            direction="column"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            height="72px"
            position="relative"
            padding="11px 14px 11px 14px"
            {...getOverrideProps(overrides, "Card Area30892310")}
          >
            <Text
              fontFamily="Inter"
              fontSize="12px"
              fontWeight="400"
              color="rgba(92,102,112,1)"
              lineHeight="24px"
              textAlign="center"
              display="flex"
              direction="column"
              justifyContent="center"
              letterSpacing="0px"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Primary Phone"
              {...getOverrideProps(overrides, "label goes here30892402")}
            ></Text>
            <Badge
              display="flex"
              gap="5px"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="99px"
              backgroundColor="rgba(184,206,249,1)"
              size="small"
              variation="default"
              children={patient?.primaryphone}
              {...getOverrideProps(overrides, "Badge30892313")}
            ></Badge>
          </Flex>
          <Divider
            width="2px"
            height="65px"
            shrink="0"
            alignSelf="stretch"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider31157500")}
          ></Divider>
        </Flex>
        <Flex
          gap="10px"
          direction="row"
          height="65px"
          justifyContent="flex-end"
          alignItems="center"
          grow="1"
          basis="166px"
          alignSelf="stretch"
          position="relative"
          padding="20px 32px 20px 32px"
          {...getOverrideProps(overrides, "Frame 438")}
        >
          <Text
            fontFamily="Inter"
            fontSize="12px"
            fontWeight="400"
            color="rgba(64,170,191,1)"
            lineHeight="24px"
            textAlign="center"
            display="flex"
            direction="column"
            justifyContent="center"
            letterSpacing="0px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Enrollment Status"
            {...getOverrideProps(overrides, "Enrollment Status")}
          ></Text>
        </Flex>
      </Flex>
    </Flex>
  );
}
