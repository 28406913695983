/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import {
  Alert,
  Badge,
  Button,
  CheckboxField,
  Divider,
  Flex,
  Icon,
  Image,
  Text,
  TextField,
} from "@aws-amplify/ui-react";
export default function StudyDetails(props) {
  const { patient, study, overrides, ...rest } = props;
  return (
    <Flex
      gap="0"
      direction="row"
      width="1066px"
      height="886px"
      alignItems="flex-start"
      position="relative"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      padding="0px 0px 0px 0px"
      as="form"
      itemProp={study?.id}
      {...rest}
      {...getOverrideProps(overrides, "StudyDetails")}
    >
      <Flex
        gap="0"
        direction="row"
        width="1066px"
        height="886px"
        alignItems="flex-start"
        shrink="0"
        overflow="hidden"
        position="relative"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "ptcard-large")}
      >
        <Flex
          gap="1px"
          direction="column"
          width="686px"
          height="886px"
          justifyContent="space-between"
          shrink="0"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="22px 22px 22px 22px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Card Area31127051")}
        >
          <Image
            height="224px"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            src={study?.image}
            {...getOverrideProps(overrides, "image")}
          ></Image>
          <Text
            fontFamily="Poppins"
            fontSize="32px"
            fontWeight="700"
            color="rgba(0,34,102,1)"
            lineHeight="40px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={`${"Study for "}${study?.Indication}`}
            {...getOverrideProps(overrides, "Study Title")}
          ></Text>
          <Flex
            gap="3px"
            direction="row"
            height="43px"
            alignItems="center"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Tags31127053")}
          >
            <Badge
              display="flex"
              gap="10px"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              height="26px"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="99px"
              backgroundColor="rgba(239,240,240,1)"
              size="small"
              variation="default"
              children={study?.Sponsor}
              {...getOverrideProps(overrides, "Badge31127054")}
            ></Badge>
            <Badge
              display="flex"
              gap="10px"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              height="26px"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="99px"
              backgroundColor="rgba(239,240,240,1)"
              size="small"
              variation="default"
              children={study?.Protocol}
              {...getOverrideProps(overrides, "Badge31127055")}
            ></Badge>
            <Badge
              display="flex"
              gap="10px"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              height="26px"
              position="relative"
              boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
              borderRadius="99px"
              backgroundColor="rgba(239,240,240,1)"
              size="small"
              variation="default"
              children={study?.clinicaltrials_gov_NCTcode}
              {...getOverrideProps(overrides, "Badge31127056")}
            ></Badge>
            <Flex
              gap="5px"
              direction="row"
              width="fit-content"
              alignItems="center"
              shrink="0"
              height="26px"
              position="relative"
              padding="0px 0px 0px 0px"
              backgroundColor="rgba(255,255,255,1)"
              {...getOverrideProps(overrides, "Tags31127057")}
            >
              <Badge
                display="flex"
                gap="10px"
                direction="row"
                width="fit-content"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                height="26px"
                position="relative"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                borderRadius="99px"
                backgroundColor="rgba(184,206,249,1)"
                size="small"
                variation="default"
                {...getOverrideProps(overrides, "Badge31127058")}
              ></Badge>
              <Badge
                display="flex"
                gap="10px"
                direction="row"
                width="fit-content"
                justifyContent="center"
                alignItems="center"
                shrink="0"
                height="26px"
                position="relative"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                borderRadius="99px"
                backgroundColor="rgba(125,161,232,1)"
                size="small"
                variation="default"
                {...getOverrideProps(overrides, "Badge31127059")}
              ></Badge>
            </Flex>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="397px"
            height="21px"
            alignItems="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            {...getOverrideProps(overrides, "Quote")}
          >
            <Icon
              width="24px"
              height="24px"
              shrink="0"
              overflow="hidden"
              position="relative"
              padding="0px 0px 0px 0px"
              viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
              paths={[
                {
                  d: "M16 0L2 0C0.9 0 0.00999999 0.9 0.00999999 2L0 16C0 17.1 0.9 18 2 18L16 18C17.1 18 18 17.1 18 16L18 2C18 0.9 17.1 0 16 0ZM16 16L2 16L2 2L16 2L16 16ZM7.5 14L10.5 14L10.5 10.5L14 10.5L14 7.5L10.5 7.5L10.5 4L7.5 4L7.5 7.5L4 7.5L4 10.5L7.5 10.5L7.5 14Z",
                  fill: "rgba(13,26,38,1)",
                  fillRule: "nonzero",
                  style: { transform: "translate(12.5%, 12.5%)" },
                },
              ]}
              type="local_hospital"
              fontSize="24px"
              {...getOverrideProps(overrides, "Icon")}
            ></Icon>
            <Text
              fontFamily="Poppins"
              fontSize="16px"
              fontWeight="400"
              color="rgba(48,64,80,1)"
              lineHeight="24px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              letterSpacing="0.01px"
              width="363px"
              grow="1"
              basis="363px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="Study Description"
              {...getOverrideProps(overrides, "Site Information31127062")}
            ></Text>
          </Flex>
          <Text
            fontFamily="Poppins"
            fontSize="16px"
            fontWeight="400"
            color="rgba(48,64,80,1)"
            lineHeight="24px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.01px"
            height="103px"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children={study?.Description}
            {...getOverrideProps(overrides, "Site Information31127063")}
          ></Text>
          <Divider
            height="1px"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            orientation="horizontal"
            {...getOverrideProps(overrides, "Divider")}
          ></Divider>
          <Text
            fontFamily="Poppins"
            fontSize="16px"
            fontWeight="700"
            color="rgba(0,34,102,1)"
            lineHeight="24px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Is this study a good fit for you?"
            {...getOverrideProps(overrides, "See if you qualify")}
          ></Text>
          <Flex
            gap="20px"
            direction="column"
            width="597px"
            shrink="0"
            position="relative"
            boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
            padding="10px 10px 10px 30px"
            backgroundColor="rgba(184,206,249,0.25)"
            {...getOverrideProps(overrides, "Frame 422")}
          >
            <CheckboxField
              display="flex"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="small"
              defaultChecked={false}
              isDisabled={false}
              labelPosition="start"
              label={study?.qual1}
              {...getOverrideProps(overrides, "CheckboxField31127218")}
            ></CheckboxField>
            <CheckboxField
              display="flex"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="small"
              defaultChecked={false}
              isDisabled={false}
              labelPosition="start"
              label={study?.qual2}
              {...getOverrideProps(overrides, "CheckboxField31127225")}
            ></CheckboxField>
            <CheckboxField
              display="flex"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="small"
              defaultChecked={false}
              isDisabled={false}
              labelPosition="start"
              label={study?.qual3}
              {...getOverrideProps(overrides, "CheckboxField31127232")}
            ></CheckboxField>
            <CheckboxField
              display="flex"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="small"
              defaultChecked={false}
              isDisabled={false}
              labelPosition="start"
              label={study?.qual4}
              {...getOverrideProps(overrides, "CheckboxField31127239")}
            ></CheckboxField>
            <CheckboxField
              display="flex"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              size="small"
              defaultChecked={false}
              isDisabled={false}
              labelPosition="start"
              label={study?.qual5}
              {...getOverrideProps(overrides, "CheckboxField31127246")}
            ></CheckboxField>
          </Flex>
          <Flex
            gap="10px"
            direction="row"
            width="597px"
            height="138px"
            justifyContent="center"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="10px 10px 10px 10px"
            {...getOverrideProps(overrides, "Frame 424")}
          >
            <Button
              display="flex"
              direction="row"
              width="fit-content"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              height="34px"
              position="relative"
              borderRadius="4px"
              size="small"
              isDisabled={false}
              variation="default"
              children="Check if you Qualify"
              {...getOverrideProps(overrides, "Button31127080")}
            ></Button>
            <Flex
              gap="10px"
              direction="row"
              width="fit-content"
              height="118px"
              alignItems="flex-start"
              shrink="0"
              alignSelf="stretch"
              position="relative"
              padding="10px 10px 10px 10px"
              {...getOverrideProps(overrides, "Frame 423")}
            >
              <Alert
                display="flex"
                gap="16px"
                direction="row"
                height="98px"
                justifyContent="center"
                alignItems="center"
                grow="1"
                basis="410px"
                alignSelf="stretch"
                position="relative"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                padding="12px 16px 12px 16px"
                backgroundColor="rgba(214,245,219,1)"
                variation="success"
                isDismissible={false}
                hasIcon={true}
                {...getOverrideProps(overrides, "Alert")}
              ></Alert>
            </Flex>
          </Flex>
        </Flex>
        <Flex
          gap="4px"
          direction="column"
          width="380px"
          height="886px"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          position="relative"
          boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
          padding="6px 20px 6px 20px"
          backgroundColor="rgba(255,255,255,1)"
          {...getOverrideProps(overrides, "Card Area31127083")}
        >
          <Flex
            gap="10px"
            direction="column"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 30px 0px"
            {...getOverrideProps(overrides, "Frame 436")}
          >
            <Flex
              gap="10px"
              direction="column"
              justifyContent="center"
              alignItems="center"
              shrink="0"
              position="relative"
              padding="0px 0px 0px 0px"
              {...getOverrideProps(overrides, "Frame 434")}
            >
              <Text
                fontFamily="Poppins"
                fontSize="40px"
                fontWeight="700"
                color="rgba(0,0,0,1)"
                lineHeight="48px"
                textAlign="left"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                whiteSpace="pre-wrap"
                children="Sign Up Now!"
                {...getOverrideProps(overrides, "Sign Up Now")}
              ></Text>
              <Flex
                gap="10px"
                direction="row"
                width="fit-content"
                alignItems="flex-start"
                shrink="0"
                position="relative"
                padding="0px 0px 0px 0px"
                {...getOverrideProps(overrides, "Frame 435")}
              >
                <Flex
                  gap="10px"
                  direction="row"
                  width="fit-content"
                  alignItems="flex-start"
                  shrink="0"
                  height="19px"
                  position="relative"
                  padding="0px 0px 0px 0px"
                  {...getOverrideProps(overrides, "Frame 433")}
                >
                  <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(0,0,0,1)"
                    lineHeight="18.75px"
                    textAlign="left"
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children={study?.Sponsor}
                    {...getOverrideProps(overrides, "Sponsor")}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="16px"
                    fontWeight="400"
                    color="rgba(0,0,0,1)"
                    lineHeight="18.75px"
                    textAlign="left"
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children={study?.Protocol}
                    {...getOverrideProps(overrides, "Protocol")}
                  ></Text>
                  <Text
                    fontFamily="Inter"
                    fontSize="8px"
                    fontWeight="400"
                    color="rgba(0,0,0,1)"
                    lineHeight="18.75px"
                    textAlign="left"
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    shrink="0"
                    position="relative"
                    padding="0px 0px 0px 0px"
                    whiteSpace="pre-wrap"
                    children=" "
                    {...getOverrideProps(overrides, "Study ID")}
                  ></Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <TextField
            display="flex"
            gap="4px"
            direction="column"
            width="300px"
            height="64px"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            isMultiline={false}
            label="Full Name"
            {...getOverrideProps(overrides, "Nameinput")}
          ></TextField>
          <TextField
            display="flex"
            gap="4px"
            direction="column"
            width="300px"
            height="64px"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            isMultiline={false}
            label="Phone number"
            {...getOverrideProps(overrides, "primaryphoneinput")}
          ></TextField>
          <TextField
            display="flex"
            gap="4px"
            direction="column"
            width="300px"
            height="64px"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            isMultiline={false}
            label="Email address"
            {...getOverrideProps(overrides, "secondaryphoneinput")}
          ></TextField>
          <TextField
            display="flex"
            gap="4px"
            direction="column"
            width="300px"
            height="64px"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="default"
            isDisabled={false}
            labelHidden={false}
            variation="default"
            isMultiline={false}
            label="Zip code"
            {...getOverrideProps(overrides, "dateofbirthinput31127258")}
          ></TextField>
          <Flex
            gap="10px"
            direction="row"
            alignItems="flex-start"
            shrink="0"
            alignSelf="stretch"
            objectFit="cover"
            position="relative"
            padding="14px 0px 14px 0px"
            {...getOverrideProps(overrides, "Frame 437")}
          >
            <Text
              fontFamily="Poppins"
              fontSize="14px"
              fontWeight="400"
              color="rgba(0,0,0,1)"
              lineHeight="20px"
              textAlign="left"
              display="flex"
              direction="column"
              justifyContent="flex-start"
              width="340px"
              grow="1"
              basis="340px"
              position="relative"
              padding="0px 0px 0px 0px"
              whiteSpace="pre-wrap"
              children="By signing up for this study, you agree that we may keep your information for the purposes of research studies. You agree to receiving calls, texts, and emails pertaining to this and other research studies. You may opt out at any time."
              {...getOverrideProps(overrides, "Opt-in")}
            ></Text>
          </Flex>
          <Button
            display="flex"
            gap="10px"
            direction="row"
            width="100px"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            position="relative"
            borderRadius="4px"
            backgroundColor="rgba(26,188,254,1)"
            size="default"
            isDisabled={false}
            variation="primary"
            children="Sign Up"
            {...getOverrideProps(overrides, "Button31127262")}
          ></Button>
          <TextField
            display="flex"
            gap="4px"
            direction="column"
            width="300px"
            height="30px"
            justifyContent="space-between"
            alignItems="center"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            size="small"
            isDisabled={false}
            labelHidden={true}
            variation="quiet"
            isMultiline={false}
            {...getOverrideProps(overrides, "dateofbirthinput31147356")}
          ></TextField>
        </Flex>
      </Flex>
    </Flex>
  );
}
