/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  useNavigateAction,
} from "@aws-amplify/ui-react/internal";
import { Divider, Flex, Image, Text } from "@aws-amplify/ui-react";
export default function DefaultNavBar(props) {
  const { overrides, ...rest } = props;
  const logoThreeZeroNineSixOneTwoThreeTwoSixOnClick = useNavigateAction({
    type: "url",
    url: "/",
  });
  const browseStudiesOnClick = useNavigateAction({
    type: "url",
    url: "/study",
  });
  const whatisaClinicalTrialQuestionMarkOnClick = useNavigateAction({
    type: "url",
    url: "/study/faq",
  });
  const signUpOnClick = useNavigateAction({
    type: "url",
    url: "/patient/signup",
  });
  return (
    <Flex
      gap="20px"
      direction="row"
      width="1440px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      padding="24px 32px 24px 32px"
      backgroundColor="#1ABCFE"
      {...rest}
      {...getOverrideProps(overrides, "DefaultNavBar")}
    >
      <Flex
        gap="2px"
        direction="row"
        width="fit-content"
        height="54px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        onClick={() => {
          logoThreeZeroNineSixOneTwoThreeTwoSixOnClick();
        }}
        {...getOverrideProps(overrides, "Logo309612326")}
      >
        <Text
          fontFamily="Inter"
          fontSize="large"
          fontWeight="bold"
          color="white"
          textTransform="capitalize"
          lineHeight="23.4375px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          position="relative"
          padding="5px"
          whiteSpace="pre-wrap"
          children={`${"JENESIS"}${" by "}`}
          {...getOverrideProps(overrides, "Logo309612328")}
        ></Text>
        <Image
          width="100px"
          height="58px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          src="https://javararesearch.com/wp-content/themes/javara/img/javara-logo-white.svg"
          {...getOverrideProps(overrides, "heroic 1")}
        ></Image>
      </Flex>
      <Flex
        gap="40px"
        direction="row"
        width="1036px"
        justifyContent="center"
        alignItems="center"
        grow="1"
        basis="1036px"
        height="24px"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 321")}
      >
        <Text
          fontFamily="Poppins"
          fontSize="16px"
          fontWeight="bold"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Browse Studies"
          onClick={() => {
            browseStudiesOnClick();
          }}
          {...getOverrideProps(overrides, "Browse Studies")}
        ></Text>
        <Divider
          height="24px"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          size="small"
          orientation="vertical"
          {...getOverrideProps(overrides, "Divider309612332")}
        ></Divider>
        <Text
          fontFamily="Poppins"
          fontSize="16px"
          fontWeight="bold"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="What is a Clinical Trial?"
          onClick={() => {
            whatisaClinicalTrialQuestionMarkOnClick();
          }}
          {...getOverrideProps(overrides, "What is a Clinical Trial?")}
        ></Text>
        <Divider
          height="24px"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          size="small"
          orientation="vertical"
          {...getOverrideProps(overrides, "Divider309612334")}
        ></Divider>
        <Text
          fontFamily="Poppins"
          fontSize="16px"
          fontWeight="bold"
          color="rgba(0,0,0,1)"
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Sign Up"
          onClick={() => {
            signUpOnClick();
          }}
          {...getOverrideProps(overrides, "Sign Up")}
        ></Text>
      </Flex>
    </Flex>
  );
}
