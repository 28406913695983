import React from "react";
import axios from 'axios';


function SignUpInfo({ formData, setFormData, handleChange, setState }) {

//const { protocol } = this.state;
//const url = "https://clinicaltrials.gov/api/query/study_fields?expr=${protocol}&fields=NCTId%2CBriefTitle%2CCondition%2CEligibilityCriteria&min_rnk=1&max_rnk=&fmt=json";
const baseURL = "https://clinicaltrials.gov/api/query/study_fields?expr=mrna-1345-p301&fields=NCTId%2CBriefTitle%2CCondition%2CEligibilityCriteria&min_rnk=1&max_rnk=&fmt=json";

    const [post, setPost] = React.useState(null);
 
    React.useEffect(() => {
        axios.get(baseURL).then((response) => {
          setPost(response.data);
        });
      }, []);
  
    if (!post) return null;
  return (
    
    <div className="sign-up-container">
           <div>
      <h1>{post.StudyFieldsResponse.StudyFields[0].Condition}</h1>
      <p>{post.StudyFieldsResponse.StudyFields[0].BriefTitle}</p>
      <p>{post.StudyFieldsResponse.StudyFields[0].NCTId}</p>
      <p>{JSON.parse(JSON.stringify(post.StudyFieldsResponse.StudyFields[0].EligibilityCriteria))}</p>
      
    </div>
      <input
        type="text"
        placeholder="Protocol..."
        name="protocol"
        value={formData.protocol}
        onChange={(event) =>
            setFormData({ ...formData, protocol: event.target.value })
          }
        
        />

      <input
        type="text"
        placeholder="Password..."
        value={formData.password}
        onChange={(event) =>
          setFormData({ ...formData, password: event.target.value })
        }
      />
      <input
        type="text"
        placeholder="Confirm Password..."
        value={formData.confirmPassword}
        onChange={(event) =>
          setFormData({ ...formData, confirmPassword: event.target.value })
        }
      />
    </div>
  );
}

export default SignUpInfo;