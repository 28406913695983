/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import React from "react";
import {
  getOverrideProps,
  useAuth,
  useAuthSignOutAction,
  useNavigateAction,
  useStateMutationAction,
} from "@aws-amplify/ui-react/internal";
import {
  Button,
  Divider,
  Flex,
  Icon,
  Image,
  Text,
} from "@aws-amplify/ui-react";
export default function AgentNavBar(props) {
  const { patient, overrides, ...rest } = props;
  const authAttributes = useAuth().user?.attributes ?? {};
  const [patientQueueColor, setPatientQueueColor] =
    useStateMutationAction("rgba(0,0,0,1)");
  const [wISDOMColor, setWISDOMColor] = useStateMutationAction("rgba(0,0,0,1)");
  const [agentDashboardColor, setAgentDashboardColor] =
    useStateMutationAction("rgba(0,0,0,1)");
  const [editmyProfileColor, setEditmyProfileColor] =
    useStateMutationAction("#fff000");
  const logoTwoOneSixSixTwoSixSevenFiveOnClick = useNavigateAction({
    type: "url",
    url: "/",
  });
  const patientQueueOnClick = useNavigateAction({
    type: "url",
    url: "/patientqueue",
  });
  const patientQueueOnMouseOver = () => {
    setPatientQueueColor("#ffffff");
  };
  const patientQueueOnMouseOut = () => {
    setPatientQueueColor("#000000");
  };
  const wISDOMOnClick = useNavigateAction({ type: "url", url: "/wisdom" });
  const wISDOMOnMouseOver = () => {
    setWISDOMColor("#ffffff");
  };
  const wISDOMOnMouseLeave = () => {
    setWISDOMColor("#000000");
  };
  const agentDashboardOnClick = useNavigateAction({
    type: "url",
    url: "/reports/agent",
  });
  const agentDashboardOnMouseOver = () => {
    setAgentDashboardColor("#ffffff");
  };
  const agentDashboardOnMouseLeave = () => {
    setAgentDashboardColor("#000000");
  };
  const iconOnClick = useNavigateAction({ type: "url", url: "/alerts" });
  const editmyProfileOnClick = useNavigateAction({
    type: "url",
    url: ":userEmail",
  });
  const editmyProfileOnMouseOver = () => {
    setEditmyProfileColor("#fff000");
  };
  const editmyProfileOnMouseLeave = () => {
    setEditmyProfileColor("#000000");
  };
  const buttonOnClick = useAuthSignOutAction({ global: true });
  return (
    <Flex
      gap="20px"
      direction="row"
      width="1440px"
      justifyContent="center"
      alignItems="center"
      position="relative"
      boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
      padding="24px 32px 24px 32px"
      backgroundColor="#1ABCFE"
      {...rest}
      {...getOverrideProps(overrides, "AgentNavBar")}
    >
      <Flex
        gap="2px"
        direction="row"
        width="fit-content"
        height="54px"
        justifyContent="center"
        alignItems="center"
        shrink="0"
        position="relative"
        padding="0px 0px 0px 0px"
        onClick={() => {
          logoTwoOneSixSixTwoSixSevenFiveOnClick();
        }}
        {...getOverrideProps(overrides, "Logo21662675")}
      >
        <Text
          fontFamily="Inter"
          fontSize="large"
          fontWeight="bold"
          color="white"
          textTransform="capitalize"
          lineHeight="23.4375px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          shrink="0"
          position="relative"
          padding="5px"
          whiteSpace="pre-wrap"
          children={`${"JENESIS"}${" by "}`}
          {...getOverrideProps(overrides, "Logo21662677")}
        ></Text>
        <Image
          width="100px"
          height="58px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          src="https://javararesearch.com/wp-content/themes/javara/img/javara-logo-white.svg"
          {...getOverrideProps(overrides, "heroic 1")}
        ></Image>
      </Flex>
      <Flex
        gap="40px"
        direction="row"
        width="508px"
        alignItems="center"
        grow="1"
        basis="508px"
        height="24px"
        position="relative"
        padding="0px 0px 0px 0px"
        paddingInlineStart="100px"
        {...getOverrideProps(overrides, "Frame 32118053119")}
      >
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="bold"
          color={patientQueueColor}
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Patient Queue"
          onClick={() => {
            patientQueueOnClick();
          }}
          onMouseOver={() => {
            patientQueueOnMouseOver();
          }}
          onMouseOut={() => {
            patientQueueOnMouseOut();
          }}
          {...getOverrideProps(overrides, "Patient Queue")}
        ></Text>
        <Divider
          height="24px"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          size="small"
          orientation="vertical"
          {...getOverrideProps(overrides, "Divider30962480")}
        ></Divider>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="bold"
          color={wISDOMColor}
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="WISDOM"
          onClick={() => {
            wISDOMOnClick();
          }}
          onMouseOver={() => {
            wISDOMOnMouseOver();
          }}
          onMouseLeave={() => {
            wISDOMOnMouseLeave();
          }}
          {...getOverrideProps(overrides, "WISDOM")}
        ></Text>
        <Divider
          height="24px"
          shrink="0"
          alignSelf="stretch"
          position="relative"
          padding="0px 0px 0px 0px"
          size="small"
          orientation="vertical"
          {...getOverrideProps(overrides, "Divider30962473")}
        ></Divider>
        <Text
          fontFamily="Inter"
          fontSize="16px"
          fontWeight="bold"
          color={agentDashboardColor}
          lineHeight="24px"
          textAlign="left"
          display="flex"
          direction="column"
          justifyContent="flex-start"
          letterSpacing="0.01px"
          shrink="0"
          position="relative"
          padding="0px 0px 0px 0px"
          whiteSpace="pre-wrap"
          children="Agent Dashboard"
          onClick={() => {
            agentDashboardOnClick();
          }}
          onMouseOver={() => {
            agentDashboardOnMouseOver();
          }}
          onMouseLeave={() => {
            agentDashboardOnMouseLeave();
          }}
          {...getOverrideProps(overrides, "Agent Dashboard")}
        ></Text>
      </Flex>
      <Flex
        gap="32px"
        direction="row"
        height="54px"
        justifyContent="flex-end"
        alignItems="center"
        grow="1"
        basis="508px"
        alignSelf="stretch"
        position="relative"
        padding="0px 0px 0px 0px"
        {...getOverrideProps(overrides, "Frame 32118053118")}
      >
        <Icon
          width="24px"
          height="24px"
          shrink="0"
          overflow="hidden"
          position="relative"
          padding="0px 0px 0px 0px"
          viewBox={{ minX: 0, minY: 0, width: 24, height: 24 }}
          paths={[
            {
              d: "M8 19.5C9.1 19.5 10 18.6 10 17.5L6 17.5C6 18.6 6.9 19.5 8 19.5ZM14 13.5L14 8.5C14 5.43 12.37 2.86 9.5 2.18L9.5 1.5C9.5 0.67 8.83 0 8 0C7.17 0 6.5 0.67 6.5 1.5L6.5 2.18C3.64 2.86 2 5.42 2 8.5L2 13.5L0 15.5L0 16.5L16 16.5L16 15.5L14 13.5ZM12 14.5L4 14.5L4 8.5C4 6.02 5.51 4 8 4C10.49 4 12 6.02 12 8.5L12 14.5Z",
              fill: "rgba(13,26,38,1)",
              fillRule: "nonzero",
              style: { transform: "translate(16.67%, 10.42%)" },
            },
          ]}
          type="notifications"
          fontSize="24px"
          onClick={() => {
            iconOnClick();
          }}
          {...getOverrideProps(overrides, "Icon")}
        ></Icon>
        <Flex
          gap="0"
          direction="column"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          height="36px"
          position="relative"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "Frame 322")}
        >
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="700"
            color="rgba(0,0,0,1)"
            lineHeight="18px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.41px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            placeholder="agent@javararesearch.com"
            children={authAttributes["email"]}
            {...getOverrideProps(overrides, "agent@javararesearch.com")}
          ></Text>
          <Text
            fontFamily="Inter"
            fontSize="14px"
            fontWeight="700"
            color={editmyProfileColor}
            lineHeight="18px"
            textAlign="left"
            display="flex"
            direction="column"
            justifyContent="flex-start"
            letterSpacing="0.41px"
            shrink="0"
            position="relative"
            padding="0px 0px 0px 0px"
            whiteSpace="pre-wrap"
            children="Edit my Profile"
            onClick={() => {
              editmyProfileOnClick();
            }}
            onMouseOver={() => {
              editmyProfileOnMouseOver();
            }}
            onMouseLeave={() => {
              editmyProfileOnMouseLeave();
            }}
            {...getOverrideProps(overrides, "Edit my Profile")}
          ></Text>
        </Flex>
        <Image
          width="45px"
          height="45px"
          shrink="0"
          position="relative"
          borderRadius="160px"
          padding="0px 0px 0px 0px"
          {...getOverrideProps(overrides, "image")}
        ></Image>
        <Button
          display="flex"
          direction="row"
          width="fit-content"
          justifyContent="center"
          alignItems="center"
          shrink="0"
          height="34px"
          position="relative"
          borderRadius="4px"
          size="small"
          isDisabled={false}
          variation="default"
          fontFamily="Inter"
          backgroundColor="#ffffff"
          children="Sign Out"
          onClick={() => {
            buttonOnClick();
          }}
          {...getOverrideProps(overrides, "Button")}
        ></Button>
      </Flex>
    </Flex>
  );
}
