/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAudits = /* GraphQL */ `
  mutation CreateAudits(
    $input: CreateAuditsInput!
    $condition: ModelAuditsConditionInput
  ) {
    createAudits(input: $input, condition: $condition) {
      id
      audittype
      inboundoroutbound
      patientphone
      contactID
      clinconID
      call_greeting
      call_IDverify
      call_introscript
      call_rebuttals
      agentemail
      call_narrative
      call_consent
      cc_demographics
      call_criteria
      call_outcome
      call_schedflex
      cc_dboptin
      cc_agentnotes
      call_confirmationemail
      cc_patienttouch
      call_closing
      call_ACWtime
      call_agentsentiment
      call_length
      call_pausesholds
      call_explanation
      cc_schedule
      call_overallscore
      auditornotes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateAudits = /* GraphQL */ `
  mutation UpdateAudits(
    $input: UpdateAuditsInput!
    $condition: ModelAuditsConditionInput
  ) {
    updateAudits(input: $input, condition: $condition) {
      id
      audittype
      inboundoroutbound
      patientphone
      contactID
      clinconID
      call_greeting
      call_IDverify
      call_introscript
      call_rebuttals
      agentemail
      call_narrative
      call_consent
      cc_demographics
      call_criteria
      call_outcome
      call_schedflex
      cc_dboptin
      cc_agentnotes
      call_confirmationemail
      cc_patienttouch
      call_closing
      call_ACWtime
      call_agentsentiment
      call_length
      call_pausesholds
      call_explanation
      cc_schedule
      call_overallscore
      auditornotes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteAudits = /* GraphQL */ `
  mutation DeleteAudits(
    $input: DeleteAuditsInput!
    $condition: ModelAuditsConditionInput
  ) {
    deleteAudits(input: $input, condition: $condition) {
      id
      audittype
      inboundoroutbound
      patientphone
      contactID
      clinconID
      call_greeting
      call_IDverify
      call_introscript
      call_rebuttals
      agentemail
      call_narrative
      call_consent
      cc_demographics
      call_criteria
      call_outcome
      call_schedflex
      cc_dboptin
      cc_agentnotes
      call_confirmationemail
      cc_patienttouch
      call_closing
      call_ACWtime
      call_agentsentiment
      call_length
      call_pausesholds
      call_explanation
      cc_schedule
      call_overallscore
      auditornotes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createStudy = /* GraphQL */ `
  mutation CreateStudy(
    $input: CreateStudyInput!
    $condition: ModelStudyConditionInput
  ) {
    createStudy(input: $input, condition: $condition) {
      id
      Indication
      Sponsor
      Description
      qual1
      qual2
      qual3
      qual4
      qual5
      patientstipend
      Protocol
      clinicaltrials_gov_NCTcode
      image
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updateStudy = /* GraphQL */ `
  mutation UpdateStudy(
    $input: UpdateStudyInput!
    $condition: ModelStudyConditionInput
  ) {
    updateStudy(input: $input, condition: $condition) {
      id
      Indication
      Sponsor
      Description
      qual1
      qual2
      qual3
      qual4
      qual5
      patientstipend
      Protocol
      clinicaltrials_gov_NCTcode
      image
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deleteStudy = /* GraphQL */ `
  mutation DeleteStudy(
    $input: DeleteStudyInput!
    $condition: ModelStudyConditionInput
  ) {
    deleteStudy(input: $input, condition: $condition) {
      id
      Indication
      Sponsor
      Description
      qual1
      qual2
      qual3
      qual4
      qual5
      patientstipend
      Protocol
      clinicaltrials_gov_NCTcode
      image
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      id
      name
      primaryphone
      secondaryphone
      dateofbirth
      email
      medicalrecordno
      clinconid
      enrollmentstatus
      site
      primaryprovider
      studyname
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      id
      name
      primaryphone
      secondaryphone
      dateofbirth
      email
      medicalrecordno
      clinconid
      enrollmentstatus
      site
      primaryprovider
      studyname
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      id
      name
      primaryphone
      secondaryphone
      dateofbirth
      email
      medicalrecordno
      clinconid
      enrollmentstatus
      site
      primaryprovider
      studyname
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
