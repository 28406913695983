/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAudits = /* GraphQL */ `
  query GetAudits($id: ID!) {
    getAudits(id: $id) {
      id
      audittype
      inboundoroutbound
      patientphone
      contactID
      clinconID
      call_greeting
      call_IDverify
      call_introscript
      call_rebuttals
      agentemail
      call_narrative
      call_consent
      cc_demographics
      call_criteria
      call_outcome
      call_schedflex
      cc_dboptin
      cc_agentnotes
      call_confirmationemail
      cc_patienttouch
      call_closing
      call_ACWtime
      call_agentsentiment
      call_length
      call_pausesholds
      call_explanation
      cc_schedule
      call_overallscore
      auditornotes
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAudits = /* GraphQL */ `
  query ListAudits(
    $filter: ModelAuditsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAudits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        audittype
        inboundoroutbound
        patientphone
        contactID
        clinconID
        call_greeting
        call_IDverify
        call_introscript
        call_rebuttals
        agentemail
        call_narrative
        call_consent
        cc_demographics
        call_criteria
        call_outcome
        call_schedflex
        cc_dboptin
        cc_agentnotes
        call_confirmationemail
        cc_patienttouch
        call_closing
        call_ACWtime
        call_agentsentiment
        call_length
        call_pausesholds
        call_explanation
        cc_schedule
        call_overallscore
        auditornotes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAudits = /* GraphQL */ `
  query SyncAudits(
    $filter: ModelAuditsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAudits(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        audittype
        inboundoroutbound
        patientphone
        contactID
        clinconID
        call_greeting
        call_IDverify
        call_introscript
        call_rebuttals
        agentemail
        call_narrative
        call_consent
        cc_demographics
        call_criteria
        call_outcome
        call_schedflex
        cc_dboptin
        cc_agentnotes
        call_confirmationemail
        cc_patienttouch
        call_closing
        call_ACWtime
        call_agentsentiment
        call_length
        call_pausesholds
        call_explanation
        cc_schedule
        call_overallscore
        auditornotes
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getStudy = /* GraphQL */ `
  query GetStudy($id: ID!) {
    getStudy(id: $id) {
      id
      Indication
      Sponsor
      Description
      qual1
      qual2
      qual3
      qual4
      qual5
      patientstipend
      Protocol
      clinicaltrials_gov_NCTcode
      image
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listStudies = /* GraphQL */ `
  query ListStudies(
    $filter: ModelStudyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStudies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Indication
        Sponsor
        Description
        qual1
        qual2
        qual3
        qual4
        qual5
        patientstipend
        Protocol
        clinicaltrials_gov_NCTcode
        image
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncStudies = /* GraphQL */ `
  query SyncStudies(
    $filter: ModelStudyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStudies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Indication
        Sponsor
        Description
        qual1
        qual2
        qual3
        qual4
        qual5
        patientstipend
        Protocol
        clinicaltrials_gov_NCTcode
        image
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($id: ID!) {
    getPatient(id: $id) {
      id
      name
      primaryphone
      secondaryphone
      dateofbirth
      email
      medicalrecordno
      clinconid
      enrollmentstatus
      site
      primaryprovider
      studyname
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPatients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        primaryphone
        secondaryphone
        dateofbirth
        email
        medicalrecordno
        clinconid
        enrollmentstatus
        site
        primaryprovider
        studyname
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPatients = /* GraphQL */ `
  query SyncPatients(
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPatients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        primaryphone
        secondaryphone
        dateofbirth
        email
        medicalrecordno
        clinconid
        enrollmentstatus
        site
        primaryprovider
        studyname
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
